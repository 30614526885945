import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// test
const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: HomeView,
  },
  {
    path: "/:log_slug",
    name: "IndPnl",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/IndPnl.vue"),
  },
  {
    path: "/:log_slug/all-chart",
    name: "AllChart",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/AllChart.vue"),
  },
  {
    path: "/:vwap_slug/chart",
    name: "VwapChart",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/VwapChart.vue"),
  },
  {
    path: "/monitor",
    name: "MonitorPage",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/MonitorPage.vue"),
  },
  {
    path: "/logs",
    name: "Logs",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/LogPage.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      hideForAuth: true,
    },
    component: () => import("../views/LogIn.vue"),
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/combined_chart",
    name: "Combinedhart",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/CombinedChart.vue"),
  },
  {
    path: "/combined-ind-pnl",
    name: "CombinedIndPnl",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/CombinedIndPnlView.vue"),
  },
  {
    path: "/processes",
    name: "ProcessView",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ProcessView.vue"),
  },
  {
    path: "/ctcl",
    name: "CtclView",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/CtclView.vue"),
  },
  {
    path: "/recon",
    name: "Recon",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ReconView.vue"),
  },
  {
    path: "/universal-data",
    name: "UniversalData",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/UniversalView.vue"),
  },
  {
    path: "/ctcl-v1/",
    name: "CTCLSV1",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/CTCLSView.vue"),
  },
  {
    path: "/processes-v1",
    name: "ProcessViewV1",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ProcessViewV1.vue"),
  },
  {
    path: "/ctcls-history/",
    name: "CTCLSHistory",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/CTCLSHistoryView.vue"),
  },
  {
    path: "/auth-users/",
    name: "AuthUsers",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/AuthUsers.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // if not logged in, redirect to Login page
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = localStorage.getItem("access_token");
    if (!token) {
      next("/login");
      //else continue
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  // if logged in, redirect to Home page
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    const token = localStorage.getItem("access_token");
    if (token) {
      next("/");
      //else continue
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
